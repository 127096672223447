import Link from 'next/link'
import { Formik, Form, FormikHelpers } from 'formik'
import { object, string } from 'yup'

import { SignInRequestData } from 'types/auth'
import FormButton from 'components/common/form-button'
import TextField from 'components/common/text-field'

import style from './login-form.module.scss'
import { useState } from 'react'

const LoginForm = ({ onSubmit }: { onSubmit: Function }) => {
  const [showPassword, setShowPassword] = useState(false)

  const initialValues: SignInRequestData = {
    email: '',
    password: '',
  }

  const validationSchema = object().shape({
    email: string().email('E-mail inválido').required('E-mail é obrigatório'),
    password: string()
      .min(8, 'Mínimo de 8 caracteres')
      .required('Senha é obrigatória'),
  })

  const handleSubmit = async (
    values: SignInRequestData,
    { setSubmitting }: FormikHelpers<SignInRequestData>
  ) => {
    setSubmitting(true)
    try {
      await onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form role="form">
            <div className={style['form']} aria-label="Login form">
              <TextField
                label="E-mail"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="seuemail@email.com"
                dataAttr={{ "data-cy": "email-field", "data-testid": "email-field" }}
              />
              <TextField
                label="Senha"
                placeholder="Senha"
                type="password"
                name="password"
                autoComplete="current-password"
                dataAttr={{ "data-cy": "password-field", "data-testid": "password-field" }}
                hasPassword={() => {
                  setShowPassword((prevState) => !prevState)
                }}
                showPassword={showPassword}
              />

              <FormButton
                disabled={isSubmitting}
                dataAttr={{ "data-cy": "submit-button" }}
              >
                {isSubmitting ? 'Entrando...' : 'Entrar'}
              </FormButton>
              <div className={style['pos-button-block']}>
                Não tem uma conta? <Link href="/auth/register" passHref><a className={style['color-link']}>Crie aqui</a></Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LoginForm
